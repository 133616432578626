// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap/dist/js/bootstrap")
require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// require("particles")
// direct_uploads.js

require("trix")
require("@rails/actiontext")

import CookiesEuBanner from './cookie_banner';

import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de"
require("flatpickr/dist/flatpickr.css")

// Needed for JQuery calls
window.jQuery = $;
window.$ = $;
document.addEventListener('turbolinks:before-cache', function(e) {
  var cals = document.querySelectorAll('.flatpickr-input');

  cals.forEach(function (element) {
    element.flatpickr().destroy();
  });
});

document.addEventListener("turbolinks:load", () => {
  const link = document.querySelector("#menu-toggle")
  var toggler = document.querySelector("#toggler")
  var toggled = false;
  if(link){
    link.addEventListener('click', () => {
      if(toggled){
          toggler.className = 'fas fa-chevron-left';
      } else {
          toggler.className = 'fas fa-chevron-right';
      }
    toggled = !toggled;
    })
  }


  // Bootstrap tooltips, popovers and toasts
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.toast').toast({ autohide: false })
  $('#toast').toast('show')

  flatpickr("[data-behavior='flatpickr']", {
   locale: German,
   enableTime: true,
   altInput: true,
   altFormat: "j. F Y - H:i",
   minDate: "today",
   weekNumbers: true,
   onClose: function(selectedDates, dateStr, instance){

    const id = window.location.href.split("/").slice(-1)[0].split("#")[0]
    $.ajax({
      url: "/job_applications/" + id + "/set_invite_date",
      type: "POST",
      data: { job_application: { invite_date: dateStr} }
    })}
 })

 flatpickr("[data-behavior='flatpickrDate']", {
  locale: German,
  altInput: true,
  altFormat: "j. F Y",
  minDate: "today",
  weekNumbers: true
})

flatpickr("[data-behavior='flatpickrDateSearch']", {
  locale: German,
  altInput: true,
  altFormat: "j. F Y",
  weekNumbers: true
})

  var check_all = document.querySelector("#review_select_all");
  var checkboxes = $("[id^='review_can_']");

  checkboxes.on("click", function(){
    if(!checkboxes.prop("checked") && check_all.checked){
      check_all.checked = false;
    }
  });

  $(check_all).on("click", function(){
    var checkboxes = $("[id^='review_can_']");
      if(check_all.checked){
          checkboxes.prop("checked",true);
      } else {
          checkboxes.prop("checked",false);
      }
  });

  $(function(){
    $("#menu-toggle").on("click", function(){
      $("#wrapper").toggleClass("toggled");
    })
  })
})




window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}

gtag('js', new Date());

document.addEventListener("turbolinks:load", function (event) {
    // let dev_mode = true
    // let url = "http://localhost:3000/"
    // let send_to = 'AW-10789113243/UQY2CJS6rfwCEJub05go'
    // if (!dev_mode) {
    //     url = "https://www.smarbeit.de/"
    //     send_to = 'AW-10789113243/2OtZCK2TrPwCEJub05go'
    // }
    // url = url + '?checkout=completed'

    // function gtag_report_conversion(url, price) {
    //     var callback = function () {
    //         if (typeof (url) != 'undefined') {
    //             window.location = url;
    //         }
    //     };
    //     gtag('event', 'conversion', {
    //         'send_to': send_to,
    //         'value': price,
    //         'currency': 'EUR',
    //         'transaction_id': '',
    //         'event_callback': callback
    //     });
    //     return false;
    // }

    // $("#complete_checkout").click(function (event) {
    //     let price = parseFloat(document.querySelector('#product_price').innerHTML);
    //     gtag_report_conversion(url, price);
    // });
  
  

    let cb = new CookiesEuBanner(() => {    
        gtag('config', 'AW-10789113243');
        gtag('config', 'G-XQM2YSPQEP', {
            page_location: event.data.url,
            page_path: event.srcElement.location.pathname,
            page_title: event.srcElement.title
        });
    }, true);
})